import React from 'react';
import './IcoPage.css'; 

const IcoPage = () => {
  return (
    <div className="ico-container">
      <h1>Swap QGEM/USDC</h1>

      {/* Exchange Rates Section */}
      <div className="exchange-rates">
        <p className="rate"> You can buy QGEM with USDC and sell QGEM for USDC on Raydium</p>
      </div>

      {/* ICO Invitation */}
      <p className="ico-invitation">
        The link below takes you to Raydium DEx.
      </p>

      {/* Referral Bonus */}
      <p className="referral-bonus">
        Add Liquidity and Earn <strong>0.22% fee whenever someone swaps</strong> from the QGEM/USDC pool.
      </p>


      {/* Get QGEM Button */}
      <a href="https://raydium.io/swap/?ammId=4SXcs7Q8pR3DLQFgP4tpSVAeGoqTpkphd4xni2dHGU3d" 
         target="_blank" 
         rel="noopener noreferrer" 
         className="get-qgem-button-link">
        <button className="get-qgem-button">
          Get QGEM
        </button>
      </a>
    </div>
  );
};

export default IcoPage;
